import React from 'react'
import {compose} from 'redux'
import {Grid, Typography, Button, AppBar} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Scrollchor from 'react-scrollchor'
import cta1 from '../../assets/cta1.png'

const styles = theme => ({
  navbar: {
    padding: '0 0 20px 0',
    backgroundColor: '#ccc',
    boxShadow: 'none',
    backgroundImage: `url(${cta1})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: 500,
    justifyContent: 'center',
    alignItems: 'center',
  },
  component: {
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  mainContainer: {
    maxWidth: '940px !important',
    justifyContent: 'center',
  },
  mainTitle: {
    display: 'block',
    fontWeight: 600,
    color: 'white',
    textAlign: 'center',
    padding: '50px 75px 0 75px',
    textShadow: '0 2px 2px rgb(0 0 0 / 50%)',
    [theme.breakpoints.down('sm')]: {
      padding: '45 25px 0 25px',
    },
  },
  sectionText: {
    color: 'white',
    textShadow: '0 2px 2px rgb(0 0 0 / 50%)',
    [theme.breakpoints.down('sm')]: {
      padding: '45 25px 0 25px',
    },
  },
  button: {
    backgroundColor: 'transparent',
    borderRadius: '22px',
    borderColor: 'white',
    fontWeight: '600',
    padding: '7px 66px',
    marginTop: '4em',
    letterSpacing: '1px',
    textAlign: 'center',
    marginBottom: '50px',
    '& a': {
      color: 'white',
      textDecoration: 'none !important',
    },
  },
})

class ImageGuardCTA extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <AppBar color="default" className={classes.navbar} position="static">
        <div className={classes.component}>
          <Grid container className={classes.mainContainer}>
            <Grid item md={12} xs={12}>
              <Typography component="h4" variant="h4" className={classes.mainTitle}>
                Sign Up for VuPulse ImageGuard
              </Typography>
              <br />
              <Typography component="p" className={classes.sectionText}>
                Sign Up for VuPulse ImageGuard Are you an influencer ready to protect your images?
              </Typography>
              <Button variant="outlined" className={classes.button}>
                <Scrollchor to="#getStarted">Contact Us</Scrollchor>
              </Button>
            </Grid>
          </Grid>
        </div>
      </AppBar>
    )
  }
}

ImageGuardCTA.displayName = 'ImageGuardCTA'

export default compose(withStyles(styles))(ImageGuardCTA)
