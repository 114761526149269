import React from 'react'
import {compose} from 'redux'
import {Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

import HomeApi from '../api/HomeApi'

const styles = () => ({
  vupDiff: {
    textAlign: 'center',
    maxWidth: 651,
    margin: '85px auto 0 auto',
    padding: '0 30px',
  },
})

class VuPulseDifference extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      vupDiff: null,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    const {classes} = this.props
    const {vupDiff} = this.state

    let title = ''
    let subTitle = {__html: ''}

    if (vupDiff !== null) {
      title = vupDiff.title
      subTitle = {__html: vupDiff.subTitle}
    }

    return (
      <div className={classes.vupDiff}>
        <Typography
          variant="h4"
          component="h5"
          style={{color: '#313131', fontWeight: 600, marginBottom: 30}}
        >
          {title}
        </Typography>

        <Typography
          variant="body1"
          component="p"
          style={{color: '#898989'}}
          dangerouslySetInnerHTML={subTitle}
        />
      </div>
    )
  }

  async loadData() {
    try {
      const diffs = await HomeApi.getVupDiff()
      if (diffs.length !== 0) {
        this.setState({vupDiff: diffs[0]})
      }
    } catch (e) {
      console.log(e)
    }
  }
}

VuPulseDifference.displayName = 'VuPulseDifference'

export default compose(withStyles(styles))(VuPulseDifference)
