import React from 'react'
import {compose} from 'redux'
import {withStyles} from '@material-ui/core/styles'
import {Grid, Typography} from '@material-ui/core'
import autobind from 'autobind-decorator'
import HomeApi from '../api/HomeApi'

const styles = theme => ({
  brands: {
    paddingTop: 150,
    maxWidth: '940px !important',
    margin: '0 auto',
  },
  brandsTitle: {
    color: '#313131',
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  brandItemContainer: {
    objectFit: 'contain',
    height: '150px',
    padding: '5px',
    [theme.breakpoints.down('xs')]: {
      height: '110px',
    },
  },
  brandImg: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  brandContainer: {
    padding: '32px 60px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '32px 30px 0',
    },
  },
})

function Brand({classes, brand}) {
  return (
    <Grid item xs={6} sm={3}>
      <div className={classes.brandItemContainer}>
        <img className={classes.brandImg} src={brand.logo} alt={brand.name} />
      </div>
    </Grid>
  )
}

class BrandSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      sections: [],
    }
  }

  componentWillMount() {
    this.loadData()
  }

  @autobind
  async loadData() {
    try {
      const sections = await HomeApi.getBrandSections()
      this.setState({sections, isLoading: false})
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const {classes} = this.props
    const {isLoading, sections} = this.state

    if (isLoading || sections.length === 0) {
      return null
    }

    const {header, brands} = sections[0]

    return (
      <Grid container className={classes.brands}>
        <Grid item xs={12}>
          <Typography component="h5" className={classes.brandsTitle}>
            {header}
          </Typography>
        </Grid>
        <div className={classes.brandContainer}>
          <Grid container item xs={12}>
            {brands.map(br => {
              return <Brand classes={classes} brand={br} key={br.id} />
            })}
          </Grid>
        </div>
      </Grid>
    )
  }
}

BrandSection.displayName = 'BrandSection'

export default compose(withStyles(styles))(BrandSection)
