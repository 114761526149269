import settings from '../../../config/settings'
import axios from './axios'

const {apiBaseURL} = settings

export default class HomeApi {
  static getAdsPerformBetter() {
    return axios.get(`${apiBaseURL}/ads-perform-better`)
  }

  static getCaseStudies() {
    return axios.get(`${apiBaseURL}/case-studies`)
  }

  static getBrandSections() {
    return axios.get(`${apiBaseURL}/brands`)
  }

  static getTestimonials() {
    return axios.get(`${apiBaseURL}/testimonials`)
  }

  static sendContactInfo(params) {
    return axios.post(`${apiBaseURL}/contact-info/send-info/`, params)
  }

  static getBanners() {
    return axios.get(`${apiBaseURL}/banners`)
  }

  static getVupDiff() {
    return axios.get(`${apiBaseURL}/vup-diff`)
  }

  static getPostClick(params) {
    return axios.get(`${apiBaseURL}/post-click-url-insights`, {params})
  }

  static getMeta() {
    return axios.get(`${apiBaseURL}/meta-tags`)
  }
}
