import React from 'react'
import {compose} from 'redux'
import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
  component: {
    marginTop: 52,
    paddingBottom: 50,
    paddingTop: 50,
    textAlign: 'center',
    background: 'linear-gradient(90deg, rgba(221,221,221,1) 0%, rgba(220,220,220,0.27) 100%)',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fafafa',
    },
  },
  mainContainer: {
    maxWidth: '940px !important',
    margin: '0 auto',
  },
  mainTitle: {
    display: 'block',
    fontWeight: 600,
    color: '#313131',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  sectionText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#767676',
    minHeight: 70,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  hideSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  threeFigures: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '25px',
    },
  },
  figure: {
    marginBottom: '25px',
  },
  button: {
    backgroundColor: '#4573C4',
    borderRadius: '22px',
    fontWeight: '600',
    padding: '7px 66px',
    marginTop: '4em',
    letterSpacing: '1px',
    '& a': {
      color: 'white',
      textDecoration: 'none !important',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      left: '50%',
      transform: 'translate(-50%)',
    },
  },
})

class AtScaleAndGrowing extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.component}>
        <Grid container className={classes.mainContainer}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              At Scale and Growing{' '}
            </Typography>
            <br />
            <Typography component="p" className={classes.sectionText}>
              We can jumpstart or grow your commerce business risk-free.
            </Typography>
          </Grid>
          <Grid container xs={12} className={classes.threeFigures}>
            <Grid item xs={12} md={4} className={classes.figure}>
              <Typography component="h4" variant="h4" className={classes.mainTitle}>
                100,000+{' '}
              </Typography>
              <Typography component="p" className={classes.secondarySectionText}>
                Category Top-Selling Product Articles{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} className={classes.figure}>
              <Typography component="h4" variant="h4" className={classes.mainTitle}>
                150 Million{' '}
              </Typography>
              <Typography component="p" className={classes.secondarySectionText}>
                Consumers Cateloged{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography component="h4" variant="h4" className={classes.mainTitle}>
                500+{' '}
              </Typography>
              <Typography component="p" className={classes.secondarySectionText}>
                Entertainment, Big Box & Department Store Retailers
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

AtScaleAndGrowing.displayName = 'AtScaleAndGrowing'

export default compose(withStyles(styles))(AtScaleAndGrowing)
