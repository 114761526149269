import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {connect} from 'react-redux'
import Helmet from 'react-helmet'
import Header from './common/Header.jsx'
import Footer from './common/Footer.jsx'
import NotificationContainer from './common/NotificationContainer.jsx'
import ImageUtils from './utils/ImageUtils'
import PageUtils from './utils/PageUtils'
import {GridContainerPage} from './App.styles.js'

import HomeApi from './api/HomeApi'

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      meta: null,
    }
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.getElementById('jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
    this.loadData()
  }

  render() {
    const meta = this.getMetaArray()

    return (
      <GridContainerPage
        id="appContain"
        container
        direction="column"
        justify="space-between"
        alignItems="center"
        spacing={32}
      >
        <CssBaseline />
        <Helmet
          defaultTitle={PageUtils.getTitle()}
          titleTemplate={`${PageUtils.getTitle()} - %s`}
          meta={meta}
        />
        <Header onHomeClick={() => {}} />
        {this.props.children}
        <Footer />
        <NotificationContainer />
      </GridContainerPage>
    )
  }

  async loadData() {
    try {
      const meta = await HomeApi.getMeta()
      if (meta.length !== 0) {
        this.setState({meta: meta[0]})
      }
    } catch (e) {
      console.log(e)
    }
  }

  getMetaArray() {
    const {meta} = this.state

    if (meta === null) {
      return [{property: 'og:image', content: ImageUtils.getAppLogoUrl()}]
    }

    const {
      title,
      description,
      keywords,
      ogSiteName,
      ogDescription,
      ogTitle,
      ogLocale,
      ogType,
      ogUrl,
      twitterCard,
      twitterDescription,
      twitterTitle,
      twitterSite,
      twitterCreator,
    } = meta

    return [
      {name: 'title', content: title},
      {name: 'description', content: description},
      {name: 'keywords', content: keywords},
      {property: 'og:site_name', content: ogSiteName},
      {property: 'og:description', content: ogDescription || description},
      {property: 'og:title', content: ogTitle || title},
      {property: 'og:locale', content: ogLocale},
      {property: 'og:type', content: ogType},
      {property: 'og:url', content: ogUrl},
      {property: 'og:image', content: ImageUtils.getAppLogoUrl()},
      {name: 'twitter:card', content: twitterCard},
      {name: 'twitter:description', content: twitterDescription || description},
      {name: 'twitter:title', content: twitterTitle || title},
      {name: 'twitter:site', content: twitterSite},
      {name: 'twitter:creator', content: twitterCreator},
    ]
  }
}

App.displayName = 'App'

function mapStateToProps(state) {
  return state
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(App)
