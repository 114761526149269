import React from 'react'
import {connect} from 'react-redux'
import {Grid, Typography} from '@material-ui/core'
import {initResponseStatus, setResponseStatus} from './redux/actions.responseStatus'
import img404 from '../../assets/404.png'

class NotFoundComponent extends React.Component {
  componentWillMount() {
    this.props.dispatch(setResponseStatus(404))
  }

  componentWillUnmount() {
    this.props.dispatch(initResponseStatus())
  }

  render() {
    return (
      <Grid container justify="center">
        <Grid item xs="auto">
          <img src={img404} alt="" />
          <Typography variant="h1" component="h1" align="center">
            Ooops.. Page Not Found!
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

NotFoundComponent.displayName = 'NotFoundComponent'

export default connect()(NotFoundComponent)
