import React from 'react'
import logoImg from '../../assets/logo-vupulse.svg'

const Logo = props => {
  const {classes} = props

  return (
    <span className={classes.brandImage}>
      <img src={logoImg} />
    </span>
  )
}

export {Logo}
