import React from 'react'
import {compose} from 'redux'
import {Grid, Typography, Button} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
//import VuSMSPlaceholder from '../../assets/header_background.png'
import Scrollchor from 'react-scrollchor'
import phone from '../../assets/header_phone.png'

const styles = theme => ({
  component: {
    marginTop: 52,
    paddingBottom: 33,
    textAlign: 'center',
    maxWidth: '940px !important',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fafafa',
    },
  },
  mainTitle: {
    display: 'block',
    fontWeight: 600,
    color: '#313131',
    textAlign: 'center',
  },
  section: {
    marginTop: 20,
    textAlign: 'left',
    marginBottom: 20,
    '& p': {
      fontSize: 16,
    },
  },
  sectionTitle: {
    fontSize: 21,
    fontWeight: 600,
    color: '#313131',
    marginBottom: 12,
  },
  sectionText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#767676',
    padding: '0 23px 0 23px',
    minHeight: 70,
    textAlign: 'center',
  },
  sectionImage: {
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },
  sectionLinkedImage: {
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
    },
  },
  sectionClickedImage: {
    width: 136,
  },
  sectionRoutedImage: {
    [theme.breakpoints.up('md')]: {
      marginTop: 30,
    },
  },
  button: {
    color: '#EA1F20',
    border: '1.5px solid #EA1F20',
    borderRadius: '22px',
    fontWeight: '600',
    padding: '7px 66px',
    marginTop: '4em',
    letterSpacing: '1px',
  },
  boldText: {
    fontWeight: 600,
  },
  gif: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 11px',
    backgroundSize: 'contain',
    height: 364,
    textAlign: 'center',
    backgroundImage: `url('https://media.giphy.com/media/d68Y9e5fpBkbrUTTn6/giphy.gif')`,
  },
  textContainer: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  phone: {
    width: 230,
    position: 'relative',
    top: -13,
  },
})

class VuSMS extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.component}>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              VuSMS
            </Typography>
            <br />
            <Typography component="p" className={classes.sectionText}>
              Personally connect with viewers to drive instant action -- in a native and brand safe
              environment
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Grid item className={classes.textContainer} xs={11} md={8}>
              <Grid container className={classes.textContainer} alignItems="center">
                <Grid container>
                  <Grid item xs={12} className={classes.section}>
                    <Typography>
                      <b>Digital Direct Response.</b> Encourage immediate reaction to turn awareness
                      into viewership and digital downloads.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.section}>
                    <Typography>
                      <b>Brand Loyalty.</b> Cultivate steadfast fanbase for ongoing series, movie
                      releases & promotions.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.section}>
                    <Typography>
                      <b>Next-Level Data Collection.</b> Seamless integration across website and
                      social enables unique individual and audience data sets.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container justify="center">
                <div className={classes.gif}>
                  <img src={phone} className={classes.phone} />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" className={classes.button}>
              <Scrollchor to="#getStarted">Learn More</Scrollchor>
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

VuSMS.displayName = 'VuSMS'

export default compose(withStyles(styles))(VuSMS)
