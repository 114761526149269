import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {AppBar, Grid, Typography} from '@material-ui/core'

import headerBg from '../../assets/header_background.png'
import headerGradient from '../../assets/header_gradient.png'
import headerPhone from '../../assets/header_phone.png'
import HomeApi from '../api/HomeApi'

const styles = theme => ({
  navbar: {
    padding: '0 0 20px 0',
    backgroundColor: '#ccc',
    boxShadow: 'none',

    backgroundImage: `url(${headerBg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: 544,
    marginBottom: 121,
    [theme.breakpoints.up('md')]: {
      marginBottom: 110,
      marginTop: '-148px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: ' -180px',
    },
  },
  navbarGradient: {
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${headerGradient})`,
      backgroundRepeat: 'repeat-x',
      backgroundPosition: 'top center',
    },
  },
  navbarToolbar: {
    paddingTop: 42,
    paddingLeft: 23,
    paddingRight: 23,
    paddingBottom: 50,
    margin: '0 auto',
    width: '100%',
    maxWidth: 940,
  },
  navbarMenuButton: {
    color: '#fff',
    fontSize: 20,
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  navbarMenuIcon: {
    fontSize: 40,
    textShadow: '0 2px 2px rgba(0,0,0,0.5)',
  },
  navbarMenuList: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  navbarMenuListMobile: {
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.48)',
    padding: 8,
    marginBottom: 40,
  },
  navbarMenuListMobileItem: {
    margin: 16,
  },
  deviceAnimation: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 19px',
    backgroundSize: '166px 360px',
    height: 434,
    width: 231,
    margin: '0 auto -121px auto',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      width: 273,
      height: 500,
      backgroundPosition: 'center 22px',
      backgroundSize: '197px 425px',
    },
  },
  headerContentContainer: {
    width: '100%',
    maxWidth: 940,
    margin: '-8px auto 0 auto',
    paddingLeft: 11,
    paddingRight: 11,
    paddingTop: 120,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 180,
    },
  },
  headerContentTitle: {
    color: '#fff',
    fontSize: '48px',
    letterSpacing: '-0.25px',
    lineHeight: '64px',
    textShadow: '0 2px 2px rgba(0,0,0,0.5)',
    marginBottom: '31px',
    maxWidth: 462,
  },
  headerContentText: {
    color: '#fff',
    fontSize: '18px',
    lineHeight: '32px',
    textShadow: '0 2px 2px rgba(0,0,0,0.5)',
    marginBottom: 30,
    maxWidth: 499,
  },

  phone: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
})

export class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      banner: null,
      mobileOpen: false,
    }
    this.scrollToGetStarted = this.scrollToGetStarted.bind(this)
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  toggleMobileMenu(e) {
    e.preventDefault()
    this.setState(state => ({
      mobileOpen: !state.mobileOpen,
    }))
  }

  render() {
    const {classes} = this.props
    const {banner} = this.state

    let title = ''
    let subTitle = ''
    let deviceStyle = {}
    let phoneGif = ''

    if (banner !== null) {
      title = banner.title
      subTitle = banner.subTitle
      phoneGif = banner.phoneGif
      deviceStyle = {
        backgroundImage: `url(${phoneGif})`,
      }
    }

    return (
      <AppBar color="default" className={classes.navbar} position="static">
        <div className={classes.navbarGradient}>
          <Grid container spacing={24} className={classes.headerContentContainer}>
            <Grid item xs={12} md={7}>
              <Typography variant="h1" component="h1" className={classes.headerContentTitle}>
                {title}
              </Typography>
              <Typography variant="subtitle1" component="p" className={classes.headerContentText}>
                {subTitle}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <div className={classes.deviceAnimation} style={deviceStyle}>
                <img className={classes.phone} src={headerPhone} />
              </div>
            </Grid>
          </Grid>
        </div>
      </AppBar>
    )
  }

  async loadData() {
    try {
      const banners = await HomeApi.getBanners()
      if (banners.length !== 0) {
        this.setState({banner: banners[0]})
      }
    } catch (e) {
      console.log(e)
    }
  }

  scrollToGetStarted(e) {
    e.preventDefault()
    const el = document.getElementById('getStarted')
    if (el) el.scrollIntoView()
  }
}

Header.displayName = 'Header'
Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Header)
