import {Button} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

export const StyledButton = withStyles({
  root: {
    borderRadius: 20,
    color: '#fff',
    margin: '0 10px',
  },
})(Button)

export const WiderButton = withStyles({
  root: {
    paddingLeft: 30,
    paddingRight: 30,
    border: '1px solid #fff',
    margin: '0 0 0 10px',
  },
})(StyledButton)

export const SubmitButton = withStyles({
  root: {
    color: '#EA1F20',
    borderRadius: `20px`,
    border: `2px solid #EA1F20`,
    fontFamily: `Montserrat`,
    fontSize: `15px`,
    fontWeight: `500`,
    letterSpacing: `2px`,
    lineHeight: `19px`,
  },
})(StyledButton)
