import {createMuiTheme} from '@material-ui/core/styles'

export default createMuiTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    useNextVariants: true,
    subtitle1: {
      fontWeight: '500',
    },
  },
  SvgIcon: {
    width: '2em',
  },
})
