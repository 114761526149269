import React from 'react'
import {compose} from 'redux'
import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import cx from 'classnames'
import oneClickLinked from '../../assets/one_click/linked.png'
import oneClickClicked from '../../assets/one_click/clicked.png'
import oneClickRouted from '../../assets/one_click/routed.png'
import arrowDownImage from '../../assets/one_click/arrow_down.png'
import arrowRightImage from '../../assets/one_click/arrow_right.png'

const styles = theme => ({
  component: {
    textAlign: 'center',
    marginTop: 52,
    paddingBottom: 80,
    maxWidth: '940px !important',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#EFEFEF',
    },
  },
  mainTitle: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      fontSize: 32,
      fontWeight: 600,
      color: '#313131',
    },
  },
  section: {
    marginTop: 80,
  },
  sectionTitle: {
    fontSize: 21,
    fontWeight: 600,
    color: '#313131',
    marginBottom: 12,
  },
  sectionText: {
    fontSize: 15,
    fontWeight: 500,
    color: '#767676',
    padding: '0 23px 0 23px',
    minHeight: 70,
  },
  sectionTextRead: {
    color: '#EA1F20',
  },
  sectionImage: {
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },
  sectionLinkedImage: {
    [theme.breakpoints.up('md')]: {
      marginTop: 50,
    },
  },
  sectionClickedImage: {
    width: 136,
  },
  sectionRoutedImage: {
    [theme.breakpoints.up('md')]: {
      marginTop: 30,
    },
  },
  sectionArrow: {
    verticalAlign: 'top',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url('${arrowDownImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      backgroundSize: '65px 68px',
      paddingBottom: 140,
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url('${arrowRightImage}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
      backgroundSize: '68px 65px',
      height: 272,
    },
  },
})

class OneURLClick extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.component}>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="h5" className={classes.mainTitle}>
              One url & click is all it takes
            </Typography>
          </Grid>

          <Grid item xs={12} md={4} className={classes.section}>
            <Typography component="h6" className={classes.sectionTitle}>
              Linked
            </Typography>
            <Typography component="p" className={classes.sectionText}>
              VuPulse provides a url for an ad (works across all ad types, platforms, tech stacks)
            </Typography>
            <div className={classes.sectionArrow}>
              <img
                className={cx(classes.sectionImage, classes.sectionLinkedImage)}
                src={oneClickLinked}
                width="160"
                height="160"
              />
            </div>
          </Grid>

          <Grid item xs={12} md={4} className={classes.section}>
            <Typography component="h6" className={classes.sectionTitle}>
              Clicked
            </Typography>
            <Typography component="p" className={classes.sectionText}>
              VuPulse-enabled ad clicked by consumers
            </Typography>
            <div className={classes.sectionArrow}>
              <img
                className={cx(classes.sectionImage, classes.sectionClickedImage)}
                src={oneClickClicked}
                width="136"
                height="272"
              />
            </div>
          </Grid>

          <Grid item xs={12} md={4} className={classes.section}>
            <Typography component="h6" className={classes.sectionTitle}>
              Routed
            </Typography>
            <Typography component="p" className={classes.sectionText}>
              Consumers segmented & routed to preferred destination -{' '}
              <strong className={classes.sectionTextRead}>in one click</strong>
            </Typography>
            <img
              className={cx(classes.sectionImage, classes.sectionRoutedImage)}
              src={oneClickRouted}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

OneURLClick.displayName = 'OneURLClick'

export default compose(withStyles(styles))(OneURLClick)
