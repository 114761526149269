import React from 'react'
import {compose} from 'redux'
import {Grid, Typography, Button} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Scrollchor from 'react-scrollchor'

const styles = theme => ({
  component: {
    marginTop: 52,
    paddingBottom: 33,
    textAlign: 'center',
    maxWidth: '940px !important',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fafafa',
      marginTop: 0,
    },
  },
  mainContainer: {
    backgroundColor: '#EA1F20',
    maxWidth: '940px !important',
  },
  mainTitle: {
    display: 'block',
    fontWeight: 600,
    color: 'white',
    textAlign: 'center',
    padding: '50px 75px 0 75px',

    [theme.breakpoints.down('sm')]: {
      padding: '45 25px 0 25px',
      textAlign: 'center',
    },
  },
  button: {
    backgroundColor: 'transparent',
    borderRadius: '22px',
    borderColor: 'white',
    fontWeight: '600',
    padding: '7px 66px',
    marginTop: '4em',
    letterSpacing: '1px',
    textAlign: 'center',
    left: '50%',
    transform: 'translate(-50%)',
    marginBottom: '50px',
    '& a': {
      color: 'white',
      textDecoration: 'none !important',
    },
  },
})

class RedCTA extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.component}>
        <Grid container className={classes.mainContainer}>
          <Grid item xs={12}>
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              Let VuPulse focus on your affiliate marketing so that you can focus on building up
              your content and growing your audience.
            </Typography>
          </Grid>
          <Button variant="outlined" className={classes.button}>
            <Scrollchor to="#getStarted">Contact Us</Scrollchor>
          </Button>
        </Grid>
      </div>
    )
  }
}

RedCTA.displayName = 'RedCTA'

export default compose(withStyles(styles))(RedCTA)
