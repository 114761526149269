import React from 'react'
import {compose} from 'redux'
import {Grid, Typography, List, ListItem} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Checkmark from '../../assets/icons/check.png'
import Phones from '../../assets/phones.png'

import HomeApi from '../api/HomeApi'

const styles = theme => ({
  component: {
    paddingBottom: 80,
    padding: '0 30px',
    maxWidth: '940px !important',
    margin: '0 auto',
  },
  betterTitle: {
    color: '#313131',
    fontSize: '18px',
    fontWeight: 600,
    marginTop: 72,
    marginBottom: 21,
  },
  betterCircles: {
    paddingTop: 32,
    paddingRight: 27,
    paddingBottom: 27,
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: 211,
    },
  },
  betterCircle: {
    display: 'inline-block',
    textAlign: 'center',
    padding: '5px 8px 0',
    verticalAlign: 'top',
    margin: '0 -27px -20px 0',
  },
  betterCircleFirstImg: {
    marginTop: 5,
  },
  betterCircleTitle: {
    display: 'block',
    marginTop: 15,
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 'bold',
    color: '#313131',
  },
  centered: {
    textAlign: 'center',
  },
  hideSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  imgRight: {
    marginRight: '15px',
  },
})

class PostClick extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      postClick: null,
    }
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    const {classes} = this.props
    const {postClick} = this.state

    let title = ''
    let subTitle = ''
    let bulletPoints = []

    if (postClick !== null) {
      title = postClick.title
      subTitle = postClick.subTitle
      bulletPoints = postClick.bulletPoints
    }

    return (
      <div className={classes.component}>
        <Grid container>
          <Grid item md={7} xs={12}>
            <Typography
              variant="h4"
              component="h5"
              style={{color: '#313131', fontWeight: 600, marginBottom: 30}}
            >
              {title}
            </Typography>

            <Typography variant="body1" component="p" style={{color: '#898989'}}>
              {subTitle}
            </Typography>
            <List>
              {bulletPoints.map(b => {
                return (
                  <ListItem key={b.id}>
                    <img
                      src={Checkmark}
                      className={classes.imgRight}
                      width="15"
                      height="15"
                      alt=""
                    />
                    {b.body}
                  </ListItem>
                )
              })}
            </List>
          </Grid>
          <Grid item md={5} xs={12}>
            <img className={classes.hideSmall} src={Phones} width="100%" />
          </Grid>
        </Grid>
      </div>
    )
  }

  async loadData() {
    try {
      const diffs = await HomeApi.getPostClick({expand: 'bullet_points'})
      if (diffs.length !== 0) {
        this.setState({postClick: diffs[0]})
      }
    } catch (e) {
      console.log(e)
    }
  }
}

PostClick.displayName = 'PostClick'

export default compose(withStyles(styles))(PostClick)
