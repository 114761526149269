import React from 'react'
import {compose} from 'redux'
import {Grid, Typography, Button} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import cx from 'classnames'
import Scrollchor from 'react-scrollchor'
import Eye from '../../assets/how/1-eye.png'
import Internet from '../../assets/how/2-internet.png'
import Ip from '../../assets/how/3-ip.png'
import Pin from '../../assets/how/4-pin.png'
import Os from '../../assets/how/5-os.png'
import Device from '../../assets/how/6-device.png'

const styles = theme => ({
  component: {
    textAlign: 'center',
    marginTop: 52,
    paddingBottom: 80,
    maxWidth: '940px !important',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fafafa',
    },
  },
  mainTitle: {
    display: 'block',
    fontWeight: 600,
    color: '#313131',
  },
  section: {
    marginTop: 20,
  },
  sectionTitle: {
    fontSize: 21,
    fontWeight: 600,
    color: '#313131',
    marginBottom: 12,
  },
  sectionText: {
    fontSize: 15,
    fontWeight: 500,
    color: '#767676',
    padding: '0 23px 0 23px',
    minHeight: 70,
  },
  sectionImage: {
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
    },
  },
  sectionLinkedImage: {
    [theme.breakpoints.up('md')]: {
      marginTop: 20,
    },
  },
  sectionClickedImage: {
    width: 136,
  },
  sectionRoutedImage: {
    [theme.breakpoints.up('md')]: {
      marginTop: 30,
    },
  },
  button: {
    color: '#EA1F20',
    border: '1.5px solid #EA1F20',
    borderRadius: '22px',
    fontWeight: '600',
    padding: '7px 66px',
    marginTop: '4em',
    letterSpacing: '1px',
  },
})

class HowWeDoIt extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.component}>
        <Grid container>
          <Grid item xs={12}>
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              How we do it
            </Typography>
            <br />
            <Typography component="p" className={classes.sectionText}>
              Our machine-learning algorithm optimizes, segments & routes consumers based on:
            </Typography>
          </Grid>

          <Grid item xs={6} md={2} className={classes.section}>
            <div className={classes.sectionArrow}>
              <img
                className={cx(classes.sectionImage, classes.sectionLinkedImage)}
                src={Eye}
                width="80"
                height="80"
              />
            </div>
            <Typography component="h6" className={classes.sectionTitle}>
              <br />
              Cookies
            </Typography>
          </Grid>

          <Grid item xs={6} md={2} className={classes.section}>
            <div className={classes.sectionArrow}>
              <img
                className={cx(classes.sectionImage, classes.sectionLinkedImage)}
                src={Internet}
                width="80"
                height="80"
              />
            </div>
            <Typography component="h6" className={classes.sectionTitle}>
              <br />
              ISP
            </Typography>
          </Grid>

          <Grid item xs={6} md={2} className={classes.section}>
            <div className={classes.sectionArrow}>
              <img
                className={cx(classes.sectionImage, classes.sectionLinkedImage)}
                src={Ip}
                width="80"
                height="80"
              />
            </div>
            <Typography component="h6" className={classes.sectionTitle}>
              <br />
              IP Address
            </Typography>
          </Grid>

          <Grid item xs={6} md={2} className={classes.section}>
            <div className={classes.sectionArrow}>
              <img
                className={cx(classes.sectionImage, classes.sectionLinkedImage)}
                src={Pin}
                width="80"
                height="80"
              />
            </div>
            <Typography component="h6" className={classes.sectionTitle}>
              <br />
              Geolocation
            </Typography>
          </Grid>

          <Grid item xs={6} md={2} className={classes.section}>
            <div className={classes.sectionArrow}>
              <img
                className={cx(classes.sectionImage, classes.sectionLinkedImage)}
                src={Os}
                width="80"
                height="80"
              />
            </div>
            <Typography component="h6" className={classes.sectionTitle}>
              <br />
              OS
            </Typography>
          </Grid>

          <Grid item xs={6} md={2} className={classes.section}>
            <div className={classes.sectionArrow}>
              <img
                className={cx(classes.sectionImage, classes.sectionLinkedImage)}
                src={Device}
                width="80"
                height="80"
              />
            </div>
            <Typography component="h6" className={classes.sectionTitle}>
              <br />
              Device
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button variant="outlined" className={classes.button}>
              <Scrollchor to="#getStarted">Learn More</Scrollchor>
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

HowWeDoIt.displayName = 'HowWeDoIt'

export default compose(withStyles(styles))(HowWeDoIt)
