import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Grid, Typography, TextField} from '@material-ui/core'
// import autobind from 'autobind-decorator'
// import HomeApi from '../api/HomeApi'
import {bindActionCreators, compose} from 'redux'
import connect from 'react-redux/es/connect/connect'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import autobind from 'autobind-decorator'
import {
  updateLocal,
  sendContactInfo,
  sendContactInfoFailure,
  contactInfoRequestClear,
} from './redux/actions'
import {SubmitButton} from '../common/StyledButton'
import ValidatedComponent from '../common/ValidatedComponent.jsx'
import InputValidator from '../common/InputValidator.jsx'
import {createError} from '../common/redux/actions.notifications'

const styles = theme => ({
  contactInfo: {
    paddingTop: 80,
    maxWidth: '940px !important',
    margin: '0 auto',
  },
  contactInfoTitle: {
    fontFamily: 'Montserrat',
    color: '#313131',
    fontSize: '36px',
    lineHeight: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  contactInfoItemContainer: {
    objectFit: 'contain',
    height: '150px',
    padding: '5px',
    [theme.breakpoints.down('xs')]: {
      height: '110px',
    },
  },
  contactInfoContainer: {
    padding: '32px 60px 0',
    width: `100%`,

    [theme.breakpoints.down('xs')]: {
      padding: '32px 30px 0',
    },
  },
  contactInfoInputs: {
    color: `#767676`,
    fontFamily: `Montserrat`,
    fontSize: `15px`,
    fontWeight: `600`,
    letterSpacing: `2px`,
    lineHeight: `19px`,
    marginLeft: '30px',
  },
  contactInfoInputsBoxes: {
    border: `1px solid #CBCBCB`,
    borderRadius: `7px`,
    backgroundColor: `#FFFFFF`,
    width: `100%`,
  },
  submit: {
    margin: `37px auto 0`,
    textAlign: 'center',
  },
  recaptcha: {
    width: 'fit-content',
    textAlign: 'center',
    margin: `28.5px auto 0`,
  },
  gridMargin: {
    marginBottom: `25px`,
  },
})

class ContactInfoSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasRecaptcha: false,
      callback: 'not fired',
    }
  }

  @autobind
  onChange(fieldName, e) {
    this.props.updateLocal({[fieldName]: e.target.value}, 'contactInfo')
  }

  @autobind
  onChangeRec(value) {
    if (value) {
      this.setState({hasRecaptcha: true})
    }
  }

  @autobind
  asyncScriptOnLoad() {
    this.setState({callback: 'called!'})
  }

  @autobind
  handleSubmit() {
    if (!this.state.hasRecaptcha) {
      this.props.createError('Recaptcha Needs to be Clicked!')
    } else if (this.props.isComponentValid()) {
      this.props.sendContactInfo(this.props.contactInfo)
      this.props.contactInfoRequestClear()
    } else {
      this.props.createError('Please Fill Required Fields!')
    }
  }

  render() {
    const {classes, contactInfo} = this.props
    const {errors} = contactInfo
    return (
      <Grid container className={classes.contactInfo} id="getStarted">
        <Grid item xs={12}>
          <Typography component="h5" className={classes.contactInfoTitle}>
            Get In Touch
          </Typography>
        </Grid>
        <div className={classes.contactInfoContainer}>
          <Grid item xs={12}>
            <Typography component="h5" className={classes.contactInfoInputs}>
              NAME*
            </Typography>
          </Grid>
          <Grid className={classes.gridMargin} item xs={12}>
            <InputValidator shouldValidateOnBlur={true} errors={errors.name}>
              <TextField
                required
                value={contactInfo.name}
                className={classes.contactInfoInputsBoxes}
                id="outlined-bare"
                margin="normal"
                variant="outlined"
                onChange={this.onChange.bind(this, 'name')}
              />
            </InputValidator>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h5" className={classes.contactInfoInputs}>
              EMAIL*
            </Typography>
          </Grid>
          <Grid className={classes.gridMargin} item xs={12}>
            <InputValidator shouldValidateOnBlur={true} errors={errors.email}>
              <TextField
                required
                type="email"
                value={contactInfo.email}
                className={classes.contactInfoInputsBoxes}
                id="outlined-bare"
                margin="normal"
                variant="outlined"
                onChange={this.onChange.bind(this, 'email')}
              />
            </InputValidator>
          </Grid>
          <Grid item xs={12}>
            <Typography component="h5" className={classes.contactInfoInputs}>
              COMPANY
            </Typography>
          </Grid>
          <Grid className={classes.gridMargin} item xs={12}>
            <TextField
              value={contactInfo.company}
              className={classes.contactInfoInputsBoxes}
              id="outlined-bare"
              margin="normal"
              variant="outlined"
              onChange={this.onChange.bind(this, 'company')}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component="h5" className={classes.contactInfoInputs}>
              MESSAGE
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              value={contactInfo.message}
              className={classes.contactInfoInputsBoxes}
              id="outlined-bare"
              rows="6"
              margin="normal"
              variant="outlined"
              onChange={this.onChange.bind(this, 'message')}
            />
          </Grid>
          <Grid item xs={12}>
            <ReCAPTCHA
              className={classes.recaptcha}
              sitekey="6LeMeaAUAAAAACQOZXQ5yts7NUavC6gaaPC0MaB8"
              onChange={this.onChangeRec}
              asyncScriptOnLoad={this.asyncScriptOnLoad}
            />
          </Grid>
        </div>
        <SubmitButton onClick={this.handleSubmit} className={classes.submit}>
          Submit
        </SubmitButton>
      </Grid>
    )
  }
}

ContactInfoSection.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {updateLocal, sendContactInfo, sendContactInfoFailure, createError, contactInfoRequestClear},
    dispatch,
  )
}

function mapStateToProps(state) {
  return {
    contactInfo: state.home.contactInfo,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  ValidatedComponent,
)(ContactInfoSection)
