import React from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {withStyles} from '@material-ui/core/styles'
import WhenYou from './WhenYou'
import VuPulseDifference from './VuPulseDifference'
import ClickOptimization from './ClickOptimization'
import OneURLClick from './OneURLClick'
import HowWeDoIt from './HowWeDoIt'
import PostClick from './PostClick'
import AdsPerformBetter from './AdsPerformBetter'
import CaseStudy from './CaseStudy'
import BrandSection from './BrandSection'
import Testimonials from './Testimonials'
import ContactInfo from './ContactInfo'
import VuSMS from './VuSMS'
import BannerIntelligentAds from './BannerIntelligentAds'

const styles = () => ({
  outContainer: {
    maxWidth: '100vw !important',
  },
  intelligentAds: {
    width: '100%',
  },
})

class IntelligentAds extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.intelligentAds}>
        <BannerIntelligentAds />
        <WhenYou />
        <VuPulseDifference />
        <ClickOptimization />
        <OneURLClick />
        <HowWeDoIt />
        <PostClick />
        <AdsPerformBetter />
        <VuSMS />
        <CaseStudy />
        <BrandSection />
        <Testimonials />
        <ContactInfo />
      </div>
    )
  }
}

IntelligentAds.displayName = 'IntelligentAds'

function mapStateToProps(state) {
  return {...state.home}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(IntelligentAds)
