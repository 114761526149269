import React from 'react'
import {compose} from 'redux'
import {Grid, Typography, Button} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Scrollchor from 'react-scrollchor'
import Checkmark from '../../assets/icons/check.svg'

const styles = theme => ({
  component: {
    marginTop: 52,
    paddingBottom: 50,
    paddingTop: 50,
    textAlign: 'left',
    background: 'linear-gradient(90deg, rgba(221,221,221,1) 0%, rgba(220,220,220,0.27) 100%)',

    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fafafa',
    },
  },
  mainContainer: {
    maxWidth: '940px !important',
    margin: '0 auto',
  },
  mainTitle: {
    display: 'block',
    fontWeight: 600,
    color: '#313131',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  sectionText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#767676',
    paddingRight: '65px',
    minHeight: 70,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  button: {
    backgroundColor: '#4573C4',
    borderRadius: '22px',
    fontWeight: '600',
    padding: '7px 66px',
    letterSpacing: '1px',
    '& a': {
      color: 'white',
      textDecoration: 'none !important',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      left: '50%',
      marginTop: 15,
      transform: 'translate(-50%)',
    },
  },
  imgRight: {
    width: '35',
    height: '35',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '15',
      height: '15',
    },
  },
  bulletContainer: {
    marginTop: '25px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px 0 25px',
    },
  },
  bulletTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
})

class AllInclusivePackage extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.component}>
        <Grid container className={classes.mainContainer}>
          <Grid item md={12} xs={12}>
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              The Affiliate Commerce Experience Platform
            </Typography>
            <br />
            <Typography component="p" className={classes.sectionText}>
              Unlike traditional affiliate programs, which require upfront budgets for writers,
              editors, and commerce experts, VuPulse scales your earnings with zero out-of-pocket
              costs by providing you:
            </Typography>
          </Grid>
          <Grid container className={classes.bulletContainer}>
            <Grid item xs={1} md={1}>
              <img className={classes.imgRight} src={Checkmark} width="100%" />
            </Grid>
            <Grid item xs={11} md={11}>
              <Typography component="p" className={classes.bulletTitle}>
                Affiliate Commerce Site Module & Traffic Tool{' '}
              </Typography>
              <Typography component="p" className={classes.bulletText}>
                Communicates top products bought and drives readers to the most popular affiliate
                articles
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.bulletContainer}>
            <Grid item xs={1} md={1}>
              <img className={classes.imgRight} src={Checkmark} width="100%" />
            </Grid>
            <Grid item xs={11} md={11}>
              <Typography component="p" className={classes.bulletTitle}>
                Contextually Relevant Commerce Content{' '}
              </Typography>
              <Typography component="p" className={classes.bulletText}>
                Data-informed commerce content articles for over 1,000 product categories turning
                readers into buyers{' '}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.bulletContainer}>
            <Grid item xs={1} md={1}>
              <img className={classes.imgRight} src={Checkmark} width="100%" />
            </Grid>
            <Grid item xs={11} md={11}>
              <Typography component="p" className={classes.bulletTitle}>
                First Party Sales Insights{' '}
              </Typography>
              <Typography component="p" className={classes.bulletText}>
                Relevant sales data for your specific categories and site-specific bestseller list{' '}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography
              component="p"
              className={classes.sectionText}
              style={{marginTop: '25px', marginBottom: '0'}}
            >
              Integrate a simple piece of code, and affiliate revenue is maximized while you focus
              on creating great content for your users.{' '}
            </Typography>
          </Grid>
          <Button variant="outlined" className={classes.button}>
            <Scrollchor to="#getStarted">Contact Us</Scrollchor>
          </Button>
        </Grid>
      </div>
    )
  }
}

AllInclusivePackage.displayName = 'AllInclusivePackage'

export default compose(withStyles(styles))(AllInclusivePackage)
