import React from 'react'
import {compose} from 'redux'
import {Grid, Typography, Button} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import autobind from 'autobind-decorator'
import _ from 'lodash'
import cx from 'classnames'
import HomeApi from '../api/HomeApi'

const styles = theme => ({
  caseStudy: {
    paddingTop: 39,
    maxWidth: '940px !important',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 22,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#313131',
    textAlign: 'center',
  },

  caseStudyWrapper: {
    minHeight: 328,
    marginTop: 15,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 45,
    paddingRight: 60,
    backgroundColor: '#000000',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center right',
    [theme.breakpoints.down('sm')]: {
      minHeight: 278,
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  caseStudyTitle: {
    fontSize: 32,
    fontWeight: 600,
    lineHeight: '38px',
    textAlign: 'center',
    color: '#FFF',
    letterSpacing: '1px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
      lineHeight: '28px',
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  ctaButtonWrapper: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ctaButtonWrapperMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  ctaButton: {
    color: '#fff',
    border: '1px solid #fff',
    borderRadius: '20px',
    paddingLeft: 45,
    paddingRight: 45,
    marginTop: 25,
    letterSpacing: '2px',
    [theme.breakpoints.down('sm')]: {
      color: '#EA1F20',
      borderColor: '#EA1F20',
    },
  },
  logoWrapper: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  logo: {
    width: '82%',
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
  },
})

class CaseStudy extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      caseStudy: [],
    }
    this.renderCtaButton = this.renderCtaButton.bind(this)
  }

  componentWillMount() {
    this.loadData()
  }

  @autobind
  async loadData() {
    try {
      const caseStudies = await HomeApi.getCaseStudies()
      const caseStudy = _.sample(caseStudies)
      this.setState({caseStudy, isLoading: false})
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const {classes} = this.props

    if (this.state.isLoading) {
      return null
    }

    const {caseStudy} = this.state

    return (
      <Grid container className={classes.caseStudy}>
        <Grid item xs={12}>
          <Typography component="h5" className={classes.title}>
            Success Stories
          </Typography>
          <Grid
            container
            item
            className={classes.caseStudyWrapper}
            justify="center"
            alignItems="center"
            style={{
              backgroundImage: `linear-gradient(90deg, rgba(255,0,0,.8), rgba(255,0,0,0) 100%), url('${
                caseStudy.backgroundImage
              }')`,
            }}
          >
            <Grid item xs={12} md={7}>
              <Typography component="p" className={classes.caseStudyTitle}>
                {caseStudy.title}
              </Typography>
              <div className={classes.ctaButtonWrapper}>
                <Button component="a" href={caseStudy.ctaUrl} className={classes.ctaButton}>
                  {caseStudy.ctaTitle}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={5} className={classes.logoWrapper}>
              <img src={caseStudy.logo} className={classes.logo} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={cx(classes.ctaButtonWrapper, classes.ctaButtonWrapperMobile)}>
          {this.renderCtaButton(caseStudy)}
        </Grid>
      </Grid>
    )
  }

  renderCtaButton(caseStudy) {
    const {classes} = this.props
    return (
      <Button component="a" href={caseStudy.ctaUrl} className={classes.ctaButton}>
        {caseStudy.ctaTitle}
      </Button>
    )
  }
}

CaseStudy.displayName = 'CaseStudy'

export default compose(withStyles(styles))(CaseStudy)
