import React from 'react'
import {compose} from 'redux'
import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import cx from 'classnames'
import vupLogoRed from '../../assets/vup_red.png'
import segmentImg from '../../assets/icons/segmentation.png'
import journeyImg from '../../assets/icons/journey.png'
import optimizeImg from '../../assets/icons/optimization.png'

const styles = theme => ({
  clickOptimization: {
    textAlign: 'center',
    maxWidth: '940px !important',
    margin: '0 auto',
  },
  preClickTitle: {
    color: '#313131',
    fontSize: '18px',
    fontWeight: 600,
    marginTop: 72,
  },
  preClickCircles: {
    width: 270,
    margin: '38px auto 0 auto',
  },
  preClickCircle: {
    border: '1px dashed #CBCBCB',
    borderRadius: '50%',
    width: 81,
    height: 81,
    lineHeight: '81px',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 500,
    color: '#898989',
    display: 'inline-block',
    margin: '0 9px -2px 0',
  },
  preClickCircleMargin: {
    marginLeft: '3px',
  },
  postClickTitle: {
    color: '#313131',
    fontSize: '18px',
    fontWeight: 600,
    marginTop: 72,
    marginBottom: 21,
  },
  postClickCircles: {
    paddingTop: 32,
    paddingRight: 27,
    paddingBottom: 27,
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: 211,
    },
  },
  postClickCircle: {
    height: 211,
    width: 211,
    border: '1px dashed #CBCBCB',
    borderRadius: '50%',
    display: 'inline-block',
    textAlign: 'center',
    padding: '30px 25px 0 25px',
    verticalAlign: 'top',
    margin: '0 -27px -20px 0',
  },
  postClickCircleFirstImg: {
    marginTop: 5,
  },
  postClickCircleTitle: {
    display: 'block',
    marginTop: 15,
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 'bold',
    color: '#313131',
  },
})

class ClickOptimization extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <Grid container className={classes.clickOptimization}>
        <Grid item xs={12} md={4}>
          <Typography component="h5" className={classes.preClickTitle}>
            Pre-Click Optimization
          </Typography>

          <Typography variant="body2" component="p" style={{color: '#898989'}}>
            Audience Creation & Audience Targeting
          </Typography>

          <div className={classes.preClickCircles}>
            <div className={cx(classes.preClickCircle, classes.preClickCircleMargin)}>Networks</div>
            <div className={classes.preClickCircle}>SSP</div>
            <div className={classes.preClickCircle}>DSP</div>
            <div className={classes.preClickCircle}>Social</div>
            <div className={classes.preClickCircle}>Exchanges</div>
            <div className={cx(classes.preClickCircle, classes.preClickCircleMargin)}>Search</div>
            <div className={classes.preClickCircle}>PMP</div>
          </div>
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography component="h5" className={classes.postClickTitle}>
            Post-Click Optimization
          </Typography>

          <img src={vupLogoRed} alt="VuPulse" width="188" height="52" />

          <div className={classes.postClickCircles}>
            <div className={classes.postClickCircle}>
              <img src={optimizeImg} width="119" className={classes.postClickCircleFirstImg} />
              <strong className={classes.postClickCircleTitle}>Audience Optimization</strong>
            </div>
            <div className={classes.postClickCircle}>
              <img src={journeyImg} width="96" />
              <strong className={classes.postClickCircleTitle}>Customer Journey Routing</strong>
            </div>
            <div className={classes.postClickCircle}>
              <img src={segmentImg} width="94" />
              <strong className={classes.postClickCircleTitle}>Audience Segmentation</strong>
            </div>
          </div>
        </Grid>
      </Grid>
    )
  }
}

ClickOptimization.displayName = 'ClickOptimization'

export default compose(withStyles(styles))(ClickOptimization)
