import React from 'react'
import {compose} from 'redux'
import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import autobind from 'autobind-decorator'
import cx from 'classnames'
import HomeApi from '../api/HomeApi'

const styles = theme => ({
  ads: {
    paddingTop: 10,
    maxWidth: '940px !important',
    margin: '0 auto',
  },
  adsTitle: {
    color: '#313131',
    fontSize: '24px',
    lineHeight: '36px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
    },
  },
  adCircles: {
    paddingTop: 32,
    paddingRight: 27,
    paddingBottom: 27,
    [theme.breakpoints.down('sm')]: {
      width: 265,
      paddingTop: 67,
      margin: '0 auto',
    },
  },
  adCircle: {
    height: 202,
    width: 202,
    border: '1px dashed #CBCBCB',
    borderRadius: '50%',
    display: 'inline-block',
    textAlign: 'center',
    padding: '49px 25px 0 25px',
    verticalAlign: 'top',
    margin: '0 -20px 0 0',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginTop: -35,
      '&:nth-child(even)': {
        marginLeft: 63,
      },
    },
  },
  adCircleBiggerBody: {
    paddingTop: 39,
  },
  adCircleTitle: {
    display: 'block',
    fontSize: 56,
    fontWeight: 200,
    lineHeight: '68px',
  },
  adCircleBody: {
    display: 'block',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
    color: '#767676',
  },
})

class AdCircle extends React.Component {
  render() {
    const {classes, color, title, body} = this.props
    const bigger = body.length >= 40
    return (
      <div
        className={cx(classes.adCircle, bigger && classes.adCircleBiggerBody)}
        style={{borderColor: color}}
      >
        <div className={classes.adCircleTitle} style={{color}}>
          {title}
        </div>
        <div className={classes.adCircleBody}>{body}</div>
      </div>
    )
  }
}

class AdsPerformBetter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      ads: [],
    }
  }

  componentWillMount() {
    this.loadData()
  }

  @autobind
  async loadData() {
    try {
      const ads = await HomeApi.getAdsPerformBetter()
      this.setState({ads, isLoading: false})
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    const {classes} = this.props

    if (this.state.isLoading) {
      return null
    }

    return (
      <Grid container className={classes.ads}>
        <Grid item xs={12}>
          <Typography component="h5" className={classes.adsTitle}>
            VuPulse ads perform better
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.adCircles}>
            {this.state.ads.map(ad => {
              return (
                <AdCircle
                  key={ad.id}
                  classes={classes}
                  color={ad.color}
                  body={ad.body}
                  title={ad.title}
                />
              )
            })}
          </div>
        </Grid>
      </Grid>
    )
  }
}

AdsPerformBetter.displayName = 'AdsPerformBetter'

export default compose(withStyles(styles))(AdsPerformBetter)
