import React from 'react'
import {compose} from 'redux'
import {withStyles} from '@material-ui/core/styles'

import RevenueEnhancement from './RevenueEnhancement'
import ContactInfo from '../intelligent_ads/ContactInfo'
import HowItWorks from './HowItWorks'
import AllInclusivePackage from './AllInclusivePackage'
import Partners from './Partners'
import AtScaleAndGrowing from './AtScaleAndGrowing'
import RedCTA from './RedCTA'
import BannerHome from './BannerHome'

const styles = () => ({
  AffiliateMarketing: {
    width: '100%',
  },
})

class AffiliateMarketing extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.AffiliateMarketing}>
        <BannerHome />
        <RevenueEnhancement />
        <HowItWorks />
        <AllInclusivePackage />
        <Partners />
        <AtScaleAndGrowing />
        <RedCTA />
        <ContactInfo />
      </div>
    )
  }
}

AffiliateMarketing.displayName = 'AffiliateMarketing'

export default compose(withStyles(styles))(AffiliateMarketing)
