import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import responseStatus from './common/redux/reducers.responseStatus'
import home from './intelligent_ads/redux/reducers'
import notifications from './common/redux/reducers.notifications'

const rootReducer = combineReducers({
  routing: routerReducer,
  responseStatus,
  home,
  notifications,
})

export default rootReducer
