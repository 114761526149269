import {fork, all} from 'redux-saga/effects'
import errorFlow from './common/redux/sagas.errors'
// import homeFlow from './home/redux/sagas'
import contactInfoFlow from './intelligent_ads/redux/sagas'

export default function* root() {
  yield all([
    fork(errorFlow),
    // fork(homeFlow)
    fork(contactInfoFlow),
  ])
}
