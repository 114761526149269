import React from 'react'
import {compose} from 'redux'
import {withStyles} from '@material-ui/core/styles'

import BannerImageGuard from './BannerImageGuard'
import IntroImageGuard from './IntroImageGuard'
import HowImageGuard from './HowImageGuard'
import ActionAndJoin from './ActionAndJoin'
import ContactInfo from '../intelligent_ads/ContactInfo'
import ImageGuardCTA from './ImageGuardCTA'
import PayInvoiceCTA from './PayInvoiceCTA'

const styles = () => ({
  home: {
    width: '100%',
  },
})

class Home extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.home}>
        <BannerImageGuard />
        <IntroImageGuard />
        <HowImageGuard />
        <ActionAndJoin />
        <ImageGuardCTA />
        <PayInvoiceCTA />
        <ContactInfo />
      </div>
    )
  }
}

Home.displayName = 'Home'

export default compose(withStyles(styles))(Home)
