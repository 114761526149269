import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {AppBar, Grid, Typography} from '@material-ui/core'

import headerBg from '../../assets/imageGuardBanner.png'
// import laptop from '../../assets/laptop.png'

const styles = theme => ({
  navbar: {
    padding: '100 0 20px 0',
    backgroundColor: '#ccc',
    boxShadow: 'none',
    marginTop: '-148px',
    backgroundImage: `url(${headerBg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: 400,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: ' -180px',
    },
  },

  headerContentContainer: {
    textAlign: 'center',
    width: '100%',
    maxWidth: 980,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 25,
      marginLeft: 0,
    },
  },
  headerContentTitle: {
    color: '#fff',
    fontSize: '45px',
    letterSpacing: '-0.25px',
    lineHeight: '64px',
    textShadow: '0 2px 2px rgba(0,0,0,0.5)',
    marginBottom: '31px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 462,
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 562,
      fontSize: 36,
    },
  },
  headerContentText: {
    color: '#fff',
    fontSize: '16px',
    lineHeight: '30px',
    textShadow: '0 2px 2px rgba(0,0,0,0.5)',
    marginBottom: 30,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 462,
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 562,
    },
  },
})

export class Header extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <AppBar color="default" className={classes.navbar} position="static">
        <div>
          <Grid container spacing={24} className={classes.headerContentContainer}>
            <Grid item xs={12} md={12}>
              <Typography variant="h1" component="h1" className={classes.headerContentTitle}>
                VuPulse ImageGuard{' '}
              </Typography>
              <Typography variant="subtitle1" component="p" className={classes.headerContentText}>
                Revolutionizing Protection for Influencers
                <br />
                <br />
                In the era of digital sharing, influencers face a unique challenge – the unlawful
                use of their social media images. It's a growing problem, and it's time to reclaim
                control.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </AppBar>
    )
  }
}

Header.displayName = 'Header'

export default withStyles(styles)(Header)
