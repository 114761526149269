import React from 'react'
import {compose} from 'redux'
import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import logos from '../../assets/partnerLogos.png'

const styles = theme => ({
  component: {
    marginTop: 52,
    paddingBottom: 33,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fafafa',
    },
  },
  mainContainer: {
    maxWidth: '940px !important',
    margin: '0 auto',
  },
  mainTitle: {
    display: 'block',
    fontWeight: 600,
    color: '#313131',
    textAlign: 'left',
    paddingRight: '25px',
    marginTop: '25px',
    lineHeight: '45px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  sectionText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#767676',
    paddingRight: '65px',
    minHeight: 70,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  hideSmall: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: '25px',
      paddingLeft: '25px',
      paddingTop: '45px',
    },
  },
  button: {
    backgroundColor: '#4573C4',
    borderRadius: '22px',
    fontWeight: '600',
    padding: '7px 66px',
    marginTop: '4em',
    letterSpacing: '1px',
    '& a': {
      color: 'white',
      textDecoration: 'none !important',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      left: '50%',
      transform: 'translate(-50%)',
    },
  },
})

class Partners extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.component}>
        <Grid container className={classes.mainContainer}>
          <Grid item md={7} xs={12}>
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              Partnering with all major affiliate networks that include all top retailers{' '}
            </Typography>
          </Grid>
          <Grid item md={5} xs={12}>
            <img className={classes.hideSmall} src={logos} width="100%" />
          </Grid>
        </Grid>
      </div>
    )
  }
}

Partners.displayName = 'Partners'

export default compose(withStyles(styles))(Partners)
