import {all, put, takeEvery} from 'redux-saga/effects'
import _ from 'lodash'
import * as NotificationActions from './actions.notifications'
import {formatApiErrorMessages} from '../../utils/ErrorUtils'

export default function* errorFlow() {
  yield all([takeEvery('*', createNotification)])
}

function* createNotification(action) {
  if (action.error) {
    const errorAction = createErrorNotification(action.error)
    if (errorAction) {
      yield put(errorAction)
    }
  }
}

function createErrorNotification(err) {
  console.error('Encountered error:', err)
  const status = _.get(err, 'response.status')

  if (status && status !== 401) {
    let errMessages = []
    if (_.get(err, 'response.data')) {
      try {
        errMessages = formatApiErrorMessages(err.response.data)
      } catch (ex) {
        console.error(ex)
      }
    }

    let errMessage = ''
    if (errMessages.length === 1) {
      errMessage = errMessages[0]
    } else {
      errMessage = errMessages.join('\n')
    }

    const message =
      errMessage ||
      err.message ||
      (err.responseText ? 'Error: ' + err.responseText : 'Error (see console logs)')

    return NotificationActions.createError(message)
  }
}
