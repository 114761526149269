import React from 'react'
import {compose} from 'redux'
import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import CliffHanger from '../../assets/cliff-hanger.png'

const styles = () => ({
  whenContainer: {
    margin: '0 auto',
    padding: '45px 25px 54px 25px',
    width: '100%',
    backgroundImage: `url(${CliffHanger})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    maxWidth: '940px !important',
  },
  whenTitle: {
    color: '#fff',
    fontWeight: 'bold',
  },
  whenBody: {
    color: '#fff',
    fontWeight: '500',
    '& ul': {
      paddingLeft: 20,
      margin: '13px 0 0 0',
    },
  },
  whenHalfTitle: {
    color: '#fff',
    textAlign: 'center',
    fontWeight: 'bold',
    textShadow: '0 2px 2px rgba(0,0,0,0.5)',
    lineHeight: '39px',
    fontSize: '32px',
    margin: '30 auto 10 auto',
    width: 281,
  },
})

class WhenYou extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <Grid container className={classes.whenContainer}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" component="h5" className={classes.whenTitle}>
            When you:
          </Typography>
          <Typography variant="body1" component="div" className={classes.whenBody}>
            <ul>
              <li>Identify your target audience</li>
              <li>Build the perfect campaign</li>
              <li>Get customers to click</li>
            </ul>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="h4" component="p" className={classes.whenHalfTitle}>
            You’re only halfway done.
          </Typography>
        </Grid>
      </Grid>
    )
  }
}

WhenYou.displayName = 'WhenYou'

export default compose(withStyles(styles))(WhenYou)
