import React from 'react'
import {compose} from 'redux'
import {Grid, Typography, Button} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import autobind from 'autobind-decorator'
import HomeApi from '../api/HomeApi'
import heartEyesImg from '../../assets/heart-eyes.png'
import arrowLeft from '../../assets/arrowL.png'
import arrowRight from '../../assets/arrowR.png'

const styles = theme => ({
  testimonials: {
    paddingTop: 130,
    maxWidth: '940px !important',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 80,
    },
  },
  title: {
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: 'bold',
    color: '#313131',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
      margin: '0 auto',
    },
  },
  titleHeartIcons: {
    verticalAlign: 'middle',
    height: 48,
    width: 48,
  },
  testimonialBody: {
    fontSize: 27,
    lineHeight: '40px',
    color: '#898989',
    fontWeight: 300,
    textAlign: 'center',
    margin: '30px auto 0 auto',
    maxWidth: 726,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      lineHeight: '34px',
      fontStyle: 'italic',
    },
  },
  testimonialAuthor: {
    fontSize: 18,
    fontHeight: '24px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#EA1F20',
    marginTop: 54,
  },
  arrowRightContainer: {
    textAlign: 'right',
  },
  arrowLeftButton: {
    marginTop: 76,
    padding: 0,
    minWidth: 'unset',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 140,
    },
  },
  arrowRightButton: {
    marginTop: 76,
    padding: 0,
    minWidth: 'unset',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 140,
    },
  },
  arrowImg: {
    width: 32,
    height: 64,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 11,
      height: 26,
    },
  },
})

class Testimonials extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      testimonials: [],
      active: 0,
    }
  }

  componentWillMount() {
    this.loadData()
  }

  @autobind
  async loadData() {
    try {
      const testimonials = await HomeApi.getTestimonials()
      this.setState({testimonials, isLoading: false})
    } catch (e) {
      console.error(e)
    }
  }

  @autobind
  toPrev(e) {
    e.preventDefault()
    this.setState(state => {
      const total = state.testimonials.length
      const prev = state.active - 1
      if (prev < 0) {
        return {active: total - 1}
      }
      return {active: prev}
    })
  }

  @autobind
  toNext(e) {
    e.preventDefault()
    this.setState(state => {
      const total = state.testimonials.length
      const next = state.active + 1
      if (next >= total) {
        return {active: 0}
      }
      return {active: next}
    })
  }

  render() {
    const {classes} = this.props

    if (this.state.isLoading) {
      return null
    }

    const {testimonials, active} = this.state
    const testimonial = testimonials[active]

    if (!testimonial) {
      return null
    }

    return (
      <Grid container className={classes.testimonials}>
        <Grid item xs={12}>
          <Typography component="h5" className={classes.title}>
            Why Brands <img src={heartEyesImg} alt="Love" className={classes.titleHeartIcons} />{' '}
            VuPulse
          </Typography>
          <Grid container item className={classes.testimonialsWrapper}>
            <Grid item xs={2} sm={1} className={classes.arrowLeftContainer}>
              <Button className={classes.arrowLeftButton} onClick={this.toPrev}>
                <img src={arrowLeft} className={classes.arrowImg} />
              </Button>
            </Grid>
            <Grid item xs={8} sm={10}>
              <Typography component="p" className={classes.testimonialBody}>
                {`“${testimonial.body}”`}
              </Typography>
              <Typography component="p" className={classes.testimonialAuthor}>
                {`— ${testimonial.author}`}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={1} className={classes.arrowRightContainer}>
              <Button className={classes.arrowRightButton} onClick={this.toNext}>
                <img src={arrowRight} className={classes.arrowImg} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  renderCtaButton(caseStudy) {
    const {classes} = this.props
    return (
      <Button component="a" href={caseStudy.ctaUrl} className={classes.ctaButton}>
        {caseStudy.ctaTitle}
      </Button>
    )
  }
}

Testimonials.displayName = 'Testimonials'

export default compose(withStyles(styles))(Testimonials)
