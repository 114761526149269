import {Grid, withStyles} from '@material-ui/core'

export const GridContainerPage = withStyles({
  'spacing-xs-32': {
    width: '100%',
    margin: '0 auto',
  },
})(Grid)

export const GridItemPage = withStyles(() => ({
  item: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
  },
}))(Grid)

export const GridContainerItemPage = withStyles(theme => ({
  item: {
    marginBottom: 0,
    maxWidth: 940,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
}))(GridItemPage)
