import React from 'react'
import {Link} from 'react-router'
import Grid from '@material-ui/core/Grid'
import {withStyles} from '@material-ui/core/styles'
import logoImg from '../../assets/logo.png'

const styles = theme => ({
  footerBg: {
    marginTop: 150,
    backgroundColor: '#343434',
  },
  footer: {
    backgroundColor: '#343434',
    padding: '20px',
    maxWidth: 1300,
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.up('md')]: {
      padding: '41px 48px',
    },
  },
  logo: {
    width: 149,
    [theme.breakpoints.up('md')]: {
      marginTop: -14,
    },
  },
  copyright: {
    marginTop: 23,
    color: '#fff',
    fontSize: 14,
    fontWeight: 500,
    marginBottom: 20,
  },
  mainLinks: {
    textAlign: 'right',
    paddingBottom: 16,
    '& a': {
      fontSize: '15px',
      fontWeight: 500,
      letterSpacing: '2px',
      lineHeight: '19px',
      color: '#fff',
      textTransform: 'uppercase',
      paddingLeft: 40,
      '&:first-child': {
        paddingLeft: 0,
      },
    },
  },
  subLinks: {
    textAlign: 'right',
    '& a': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
      color: '#fff',
      paddingLeft: 19,
      '&:first-child': {
        paddingLeft: 0,
      },
    },
  },
})

class Footer extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <footer className={classes.footerBg}>
        <Grid container direction="row" justify="center" className={classes.footer}>
          <Grid item xs={12} md={4}>
            <Link to="/">
              <img src={logoImg} alt="VuPulse" className={classes.logo} />
            </Link>
            <div className={classes.copyright}>© 2019 VuPulse </div>
          </Grid>

          <Grid item xs={12} md={8}>
            <div className={classes.mainLinks}>
              {/*<Link to="/">Insights</Link>*/}
              <Link to="/"> Affiliate Marketing</Link>
              <Link to="/intelligent-ads"> Intelligent Ads</Link>
              <Link component="a" href="/blog/">
                Blog
              </Link>
              <Link component="a" href="/blog/topics/news/">
                News
              </Link>
            </div>
            <div className={classes.subLinks}>
              {/*<Link to="/">Legal</Link>
              <Link to="privacy-policy">Privacy</Link>
              <Link to="/">Cookies</Link>
              <Link to="/">About Ads</Link>*/}
            </div>
          </Grid>
        </Grid>
      </footer>
    )
  }
}

Footer.displayName = 'Footer'

export default withStyles(styles)(Footer)
