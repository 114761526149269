import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {AppBar, Grid, Typography} from '@material-ui/core'

import headerBg from '../../assets/affiliateBanner.png'
import headerGradient from '../../assets/header_gradient.png'
import laptop from '../../assets/laptop.png'

const styles = theme => ({
  navbar: {
    padding: '0 0 20px 0',
    backgroundColor: '#ccc',
    boxShadow: 'none',

    backgroundImage: `url(${headerBg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    minHeight: 544,
    marginTop: '-148px',
    [theme.breakpoints.down('sm')]: {
      marginTop: ' -180px',
    },
  },
  navbarGradient: {
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${headerGradient})`,
      backgroundRepeat: 'repeat-x',
      backgroundPosition: 'top center',
    },
  },
  navbarToolbar: {
    paddingTop: 42,
    paddingLeft: 23,
    paddingRight: 23,
    paddingBottom: 50,
    margin: '0 auto',
    width: '100%',
    maxWidth: 940,
  },
  navbarMenuButton: {
    color: '#fff',
    fontSize: 20,
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  navbarMenuIcon: {
    fontSize: 40,
    textShadow: '0 2px 2px rgba(0,0,0,0.5)',
  },
  navbarMenuList: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  navbarMenuListMobile: {
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.48)',
    padding: 8,
    marginBottom: 40,
  },
  navbarMenuListMobileItem: {
    margin: 16,
  },
  deviceAnimation: {},
  headerContentContainer: {
    width: '100%',
    maxWidth: 980,
    margin: '-8px auto 0 auto',
    paddingLeft: 11,
    paddingRight: 11,
    paddingTop: 120,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 25,
    },
  },
  headerContentTitle: {
    color: '#fff',
    fontSize: '45px',
    letterSpacing: '-0.25px',
    lineHeight: '64px',
    textShadow: '0 2px 2px rgba(0,0,0,0.5)',
    marginBottom: '31px',
    maxWidth: 462,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 462,
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 562,
      marginLeft: 15,
      fontSize: 36,
    },
  },
  headerContentText: {
    color: '#fff',
    fontSize: '16px',
    lineHeight: '30px',
    textShadow: '0 2px 2px rgba(0,0,0,0.5)',
    marginBottom: 30,
    maxWidth: 499,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 462,
      marginLeft: 0,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 562,
      marginLeft: 15,
    },
  },

  laptop: {
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: '10%',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
})

export class Header extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <AppBar color="default" className={classes.navbar} position="static">
        <div className={classes.navbarGradient}>
          <Grid container spacing={24} className={classes.headerContentContainer}>
            <Grid item xs={12} md={6}>
              <Typography variant="h1" component="h1" className={classes.headerContentTitle}>
                Democratizing Affiliate Commerce{' '}
              </Typography>
              <Typography variant="subtitle1" component="p" className={classes.headerContentText}>
                Most websites are small businesses, and it is becoming more difficult for them to
                make a few dollars to survive. They don’t have the time or money to launch an
                affiliate marketing program to generate more revenue from their readers.
                <br />
                <br />
                VuPulse is democratizing the affiliate marketing program, simplifying it, and making
                it accessible for websites of all sizes.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.deviceAnimation}>
                <img className={classes.laptop} src={laptop} />
              </div>
            </Grid>
          </Grid>
        </div>
      </AppBar>
    )
  }
}

Header.displayName = 'Header'

export default withStyles(styles)(Header)
