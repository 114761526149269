import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {AppBar, Toolbar, IconButton, Icon} from '@material-ui/core'
import cx from 'classnames'
import {Link} from 'react-router'
import {StyledButton, WiderButton} from './StyledButton.jsx'
import {Logo} from './Icons.jsx'
import HomeApi from '../api/HomeApi'

const styles = theme => ({
  navbar: {
    boxShadow: 'none',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'transparent',
  },

  navbarToolbar: {
    paddingTop: 42,
    paddingLeft: 23,
    paddingRight: 23,
    paddingBottom: 50,
    margin: '0 auto',
    width: '100%',
    maxWidth: 940,
  },
  navbarMenuButton: {
    color: '#fff',
    fontSize: 20,
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  navbarMenuIcon: {
    fontSize: 40,
    textShadow: '0 2px 2px rgba(0,0,0,0.5)',
  },
  navbarMenuList: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  navbarMenuListMobile: {
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.48)',
    padding: 8,
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#343434',
    },
  },
  navbarMenuListMobileItem: {
    margin: 16,
  },
  brandImageContainer: {
    flex: 1,
  },
  brandImage: {
    maxWidth: '197px',
    display: 'block',
    filter: 'drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2))',
  },
  button: {
    padding: '0 !important',

    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
      minWidth: 0,
      fontSize: 14,
    },
  },
  buttonWider: {
    [theme.breakpoints.down('sm')]: {
      padding: '10 !important',
      fontSize: 14,
    },
  },
})

export class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      banner: null,
      mobileOpen: false,
    }
    this.scrollToGetStarted = this.scrollToGetStarted.bind(this)
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this)
  }

  componentDidMount() {
    this.loadData()
  }

  toggleMobileMenu(e) {
    e.preventDefault()
    this.setState(state => ({
      mobileOpen: !state.mobileOpen,
    }))
  }

  render() {
    const {classes} = this.props
    const {mobileOpen} = this.state

    return (
      <AppBar color="default" className={classes.navbar} position="static">
        <div className={classes.navbarGradient}>
          <Toolbar className={classes.navbarToolbar}>
            <Link className={classes.brandImageContainer} to="/">
              <Logo classes={classes} />
            </Link>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              className={classes.navbarMenuButton}
            >
              <Icon
                className={cx(classes.navbarMenuIcon, 'fa fa-bars')}
                onClick={this.toggleMobileMenu}
              />
            </IconButton>
            <div className={classes.navbarMenuList}>
              <StyledButton className={classes.button}>
                <Link
                  style={{
                    borderRadius: 20,
                    color: '#fff',
                    margin: '0 10px',
                    textDecoration: 'none',
                  }}
                  to="/affiliate-marketing"
                >
                  Affiliate Marketing
                </Link>
              </StyledButton>

              <StyledButton className={classes.button}>
                <Link
                  style={{
                    borderRadius: 20,
                    color: '#fff',
                    margin: '0 10px',
                    textDecoration: 'none',
                  }}
                  to="/intelligent-ads"
                >
                  Intelligent Ads
                </Link>
              </StyledButton>
              <StyledButton component="a" href="/blog/" className={classes.button}>
                Blog
              </StyledButton>
              <StyledButton component="a" href="/blog/topics/news/" className={classes.button}>
                News
              </StyledButton>
              <WiderButton onClick={this.scrollToGetStarted} className={classes.buttonWider}>
                Get Started
              </WiderButton>
            </div>
          </Toolbar>

          {mobileOpen && (
            <div className={classes.navbarMenuListMobile}>
              <div className={classes.navbarMenuListMobileItem}>
                <StyledButton>
                  <Link
                    style={{
                      borderRadius: 20,
                      color: '#fff',
                      margin: '0 10px',
                      textDecoration: 'none',
                    }}
                    to="/affiliate-marketing"
                  >
                    Affiliate Marketing
                  </Link>
                </StyledButton>
              </div>
              <div className={classes.navbarMenuListMobileItem}>
                <StyledButton>
                  <Link
                    style={{
                      borderRadius: 20,
                      color: '#fff',
                      margin: '0 10px',
                      textDecoration: 'none',
                    }}
                    to="/intelligent-ads"
                  >
                    Intelligent Ads
                  </Link>
                </StyledButton>
              </div>
              <div className={classes.navbarMenuListMobileItem}>
                <StyledButton component="a" href="/blog/">
                  Blog
                </StyledButton>
              </div>
              <div className={classes.navbarMenuListMobileItem}>
                <StyledButton component="a" href="/blog/topics/news/">
                  News
                </StyledButton>
              </div>
              <div className={classes.navbarMenuListMobileItem}>
                <WiderButton onClick={this.scrollToGetStarted}>Get Started</WiderButton>
              </div>
            </div>
          )}
        </div>
      </AppBar>
    )
  }

  async loadData() {
    try {
      const banners = await HomeApi.getBanners()
      if (banners.length !== 0) {
        this.setState({banner: banners[0]})
      }
    } catch (e) {
      console.log(e)
    }
  }

  scrollToGetStarted(e) {
    e.preventDefault()
    const el = document.getElementById('getStarted')
    if (el) el.scrollIntoView()
  }
}

Header.displayName = 'Header'
Header.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Header)
