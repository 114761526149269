import React from 'react'
import {compose} from 'redux'
import {Grid, Typography, Button} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Scrollchor from 'react-scrollchor'
import RevenueEnhancementImage from '../../assets/revenueEnhancement.svg'

const styles = theme => ({
  component: {
    paddingBottom: 50,
    paddingTop: 50,
    textAlign: 'left',
    background: 'linear-gradient(90deg, rgba(221,221,221,1) 0%, rgba(220,220,220,0.27) 100%)',

    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fafafa',
    },
  },
  mainContainer: {
    maxWidth: '940px !important',
    margin: '0 auto',
  },
  mainTitle: {
    display: 'block',
    fontWeight: 600,
    color: '#313131',
    textAlign: 'left',
    paddingRight: '55px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  sectionText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#767676',
    paddingRight: '80px',
    minHeight: 70,
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  hideSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  button: {
    backgroundColor: '#4573C4',
    borderRadius: '22px',
    fontWeight: '600',
    padding: '7px 66px',
    marginTop: '4em',
    letterSpacing: '1px',
    '& a': {
      color: 'white',
      textDecoration: 'none !important',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      left: '50%',
      transform: 'translate(-50%)',
    },
  },
})

class RevenueEnhancement extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.component}>
        <Grid container className={classes.mainContainer}>
          <Grid item md={7} xs={12}>
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              VuPulse: A Complete Affiliate Commerce Solution
            </Typography>
            <br />
            <Typography component="p" className={classes.sectionText}>
              A services and technology company that helps publishers make more revenue from their
              user base by launching and managing their affiliate businesses more efficiently.
              Allowing publishers to focus on building up their content and growing their audience.{' '}
            </Typography>
            <Button variant="outlined" className={classes.button}>
              <Scrollchor to="#getStarted">Contact Us</Scrollchor>
            </Button>
          </Grid>
          <Grid item md={5} xs={12}>
            <img className={classes.hideSmall} src={RevenueEnhancementImage} width="100%" />
          </Grid>
        </Grid>
      </div>
    )
  }
}

RevenueEnhancement.displayName = 'RevenueEnhancement'

export default compose(withStyles(styles))(RevenueEnhancement)
