import React from 'react'
import {IndexRoute, Route} from 'react-router'
import App from './App.jsx'
import NotFound from './common/NotFound.Page.jsx'
import Home from './home/Home.jsx'
import AffiliateMarketing from './affiliate_marketing/AffiliateMarketing.jsx'
import IntelligentAds from './intelligent_ads/IntelligentAds.jsx'

export default function routing() {
  return (
    <Route path="/" component={App}>
      <IndexRoute component={Home} />
      <Route path="/affiliate-marketing" component={AffiliateMarketing} />
      <Route path="/intelligent-ads" component={IntelligentAds} />
      {/* should always be at the end */}
      <Route path="*" component={NotFound} />
    </Route>
  )
}
