import React from 'react'
import {compose} from 'redux'
import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import actionAndJoin from '../../assets/actionAndJoin.png'

const styles = theme => ({
  component: {
    paddingBottom: 50,
    paddingTop: 50,
    textAlign: 'left',
    background: 'linear-gradient(90deg, rgba(221,221,221,1) 0%, rgba(220,220,220,0.27) 100%)',

    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fafafa',
    },
  },
  mainContainer: {
    maxWidth: '940px !important',
    margin: '0 auto',
    alignItems: 'center',
  },
  mainTitle: {
    display: 'block',
    fontWeight: 600,
    color: '#313131',
    textAlign: 'left',
    paddingRight: '55px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  sectionText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#767676',
    paddingRight: '80px',
    minHeight: 70,
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  hideSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
})

class ActionAndJoin extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.component}>
        <Grid container className={classes.mainContainer}>
          <Grid item md={7} xs={12}>
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              Take Decisive Action
            </Typography>
            <br />
            <Typography component="p" className={classes.sectionText}>
              VuPulse ImageGuard doesn’t just detect infringements, it empowers you to take decisive
              action. We offer extensive support in dispatching cease-and-desist letters, and if
              necessary, we can escalate the matter to our network of legal experts specializing in
              copyright law.{' '}
            </Typography>
            <br />
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              Join VuPulse ImageGuard Today{' '}
            </Typography>
            <br />
            <Typography component="p" className={classes.sectionText}>
              Your image. Your brand. Your rights. Secure them all with VuPulse ImageGuard. Stand
              against unauthorized use of your images. Register for VuPulse ImageGuard today and be
              part of the movement that champions influencer rights.{' '}
            </Typography>
          </Grid>
          <Grid item md={5} xs={12}>
            <img className={classes.hideSmall} src={actionAndJoin} width="100%" />
          </Grid>
        </Grid>
      </div>
    )
  }
}

ActionAndJoin.displayName = 'ActionAndJoin'

export default compose(withStyles(styles))(ActionAndJoin)
