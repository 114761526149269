import React from 'react'
import {compose} from 'redux'
import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import howImageGuard from '../../assets/howImageGuard.png'

const styles = theme => ({
  component: {
    paddingBottom: 50,
    paddingTop: 50,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fafafa',
      marginTop: 0,
    },
  },
  mainContainer: {
    maxWidth: '940px !important',
    margin: '0 auto',
  },
  textContainer: {
    position: 'relative',
  },
  textDiv: {
    position: 'absolute',
    top: '50%',
    '-ms-transform': 'translateY(-50%)',
    transform: 'translateY(-50%)',
    [theme.breakpoints.down('sm')]: {
      '-ms-transform': 'translateY(0%)',
      transform: 'translateY(0%)',
      position: 'static',
    },
  },
  mainTitle: {
    display: 'block',
    fontWeight: 600,
    color: '#313131',
    textAlign: 'left',
    paddingLeft: '65px',

    [theme.breakpoints.down('sm')]: {
      padding: '30px 25px 0 25px',
      textAlign: 'center',
    },
  },
  sectionText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#767676',
    paddingLeft: '65px',
    minHeight: 70,
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  hideSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
})

class HowImageGuard extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.component}>
        <Grid container className={classes.mainContainer}>
          <Grid item md={5} xs={12}>
            <img className={classes.hideSmall} src={howImageGuard} width="100%" />
          </Grid>
          <Grid item md={7} xs={12}>
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              How VuPulse ImageGuard Works{' '}
            </Typography>
            <br />
            <Typography component="p" className={classes.sectionText}>
              Our service utilizes state-of-the-art AI technology to monitor the internet for
              unauthorized use of your images. VuPulse ImageGuard operates tirelessly, scanning
              websites, blogs, digital magazines, and other online platforms around the clock.{' '}
            </Typography>
            <br />
            <Typography component="p" className={classes.sectionText}>
              When VuPulse ImageGuard spots unauthorized use, it instantly sends you an alert,
              supplying a detailed report with the website, the image, and the context in which your
              image has been used.{' '}
            </Typography>
          </Grid>
        </Grid>
      </div>
    )
  }
}

HowImageGuard.displayName = 'HowImageGuard'

export default compose(withStyles(styles))(HowImageGuard)
