import React from 'react'
import PropTypes from 'prop-types'
import autobind from 'autobind-decorator'
import classNames from 'classnames'
import {withStyles} from '@material-ui/core/styles'
import {Snackbar, SnackbarContent} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  secondary: InfoIcon,
}

class Notification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {open: true}
  }

  @autobind
  dismiss(e, reason) {
    // disable dismis on clickaway
    if (reason === 'clickaway') return

    this.setState({open: false}, () => {
      this.props.dismiss(this.props.notification)
    })
  }

  @autobind
  onClickToClose(e) {
    e.preventDefault()
    this.dismiss()
  }

  render() {
    const {notification, timeout, classes} = this.props

    if (!notification) {
      return null
    }

    const Icon = variantIcon[notification.type]

    return (
      <Snackbar
        autoHideDuration={timeout}
        open={this.state.open}
        onClose={this.dismiss}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SnackbarContent
          className={classes[notification.type]}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)} />
              {notification.message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.onClickToClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }
}

Notification.displayName = 'Notification'
Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  timeout: PropTypes.number,
  dismiss: PropTypes.func,
}
Notification.defaultProps = {
  timeout: 6000,
}

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    whiteSpace: 'pre',
  },
})

export default withStyles(styles)(Notification)
