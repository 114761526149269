import React from 'react'
import Provider from 'react-redux/es/components/Provider'
import {Router} from 'react-router'
import JssProvider from 'react-jss/lib/JssProvider'
import {MuiThemeProvider} from '@material-ui/core/styles'
import routing from './shared/routing'

export default function Root({store, history, theme, generateClassName}) {
  return (
    <Provider store={store}>
      <JssProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          <Router history={history}>{routing(store)}</Router>
        </MuiThemeProvider>
      </JssProvider>
    </Provider>
  )
}
