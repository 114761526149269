import {call, put, takeEvery} from 'redux-saga/effects'
import HomeApi from '../../api/HomeApi'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../common/redux/actions.notifications.js'

export default function* contactInfoFlow() {
  yield [takeEvery(Types.SEND_CONTACT_INFO, sendContactInfo)]
}

function* sendContactInfo(action) {
  const {params} = action
  try {
    const response = yield call(HomeApi.sendContactInfo, params)
    yield put(Actions.sendContactInfoSuccess(response))
    yield put(NotificationActions.createSuccess('Successfully Sent Info.'))
  } catch (error) {
    yield put(Actions.sendContactInfoFailure(error))
  }
}
