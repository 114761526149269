import {push} from 'react-router-redux'
import * as Types from './actions.types'

export function resetFilter() {
  return dispatch => {
    dispatch(push('/'))
  }
}

export function updateLocal(patch, rootKey) {
  return {type: Types.UPDATE_LOCAL, patch, rootKey}
}

export function sendContactInfo(params) {
  return {type: Types.SEND_CONTACT_INFO, params}
}

export function sendContactInfoSuccess(response) {
  return {type: Types.SEND_CONTACT_INFO_SUCCESS, response}
}

export function sendContactInfoFailure(error) {
  return {type: Types.SEND_CONTACT_INFO_FAILURE, error}
}

export function contactInfoRequestClear() {
  return {type: Types.CONTACT_INFO_REQUEST_CLEAR}
}
