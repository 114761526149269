import React from 'react'
import {compose} from 'redux'
import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import influencer from '../../assets/influencer.png'

const styles = theme => ({
  component: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    paddingBottom: 50,
    paddingTop: 50,
    textAlign: 'left',
    background: 'linear-gradient(90deg, rgba(221,221,221,1) 0%, rgba(220,220,220,0.27) 100%)',

    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fafafa',
    },
  },
  mainContainer: {
    maxWidth: '940px !important',
    margin: '0 auto',
    alignItems: 'center',
  },
  mainTitle: {
    display: 'block',
    fontWeight: 600,
    color: '#313131',
    textAlign: 'left',
    paddingRight: '55px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  sectionText: {
    fontSize: 16,
    fontWeight: 500,
    color: '#767676',
    paddingRight: '80px',
    minHeight: 70,
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      padding: '0 25px 0 25px',
      textAlign: 'center',
    },
  },
  hideSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
})

class IntroImageGuard extends React.Component {
  render() {
    const {classes} = this.props

    return (
      <div className={classes.component}>
        <Grid container className={classes.mainContainer}>
          <Grid item md={7} xs={12} className={classes.gridItem1}>
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              Introducing VuPulse ImageGuard
            </Typography>
            <br />
            <Typography component="p" className={classes.sectionText}>
              We are thrilled to introduce VuPulse ImageGuard, a revolutionary service designed to
              protect your creative assets. Our pioneering tool employs advanced technology to scan
              the digital landscape, identifying unauthorized use of your images.{' '}
            </Typography>
            <br />
            <Typography component="h4" variant="h4" className={classes.mainTitle}>
              Stand for Your Rights
            </Typography>
            <br />
            <Typography component="p" className={classes.sectionText}>
              Your images are not just photos - they're the essence of your brand, the face of your
              business, and a source of your income. Unauthorized use of these assets is not only
              disrespectful, but also illegal. With VuPulse ImageGuard, we recognize your worth and
              stand to protect your rights.{' '}
            </Typography>
          </Grid>
          <Grid item md={5} xs={12}>
            <img className={classes.hideSmall} src={influencer} width="100%" />
          </Grid>
        </Grid>
      </div>
    )
  }
}

IntroImageGuard.displayName = 'IntroImageGuard'

export default compose(withStyles(styles))(IntroImageGuard)
