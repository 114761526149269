import reduceReducers from 'reduce-reducers'
import * as Types from './actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'

function home(state = createInitState(), action) {
  switch (action.type) {
    case Types.CONTACT_INFO_REQUEST_CLEAR:
      return createInitState()

    case Types.UPDATE_LOCAL:
      if (action.rootKey) {
        return {
          ...state,
          [action.rootKey]: {
            ...state[action.rootKey],
            ...action.patch,
          },
        }
      }
      return {...state, ...action.patch}
    case Types.SEND_CONTACT_INFO:
      return {
        ...state,
        contactInfo: {
          errors: {},
          isSending: true,
        },
      }
    case Types.SEND_CONTACT_INFO_SUCCESS:
      return {
        ...state,
        contactInfo: {
          errors: {},
          isSending: false,
        },
      }
    case Types.SEND_CONTACT_INFO_FAILURE:
      return {
        ...state,
        contactInfo: {
          errors: ErrorUtils.getApiErrors(action.error),
          isSending: false,
        },
      }
    default:
      return state
  }
}

function createInitState() {
  return {
    isFetching: false,
    query: createInitQuery(),
    users: [],
    contactInfo: {
      errors: {},
      isSending: false,
      name: '',
      email: '',
      message: '',
      company: '',
    },
  }
}

function createInitQuery() {
  return {
    page: null,
  }
}

const reducer = reduceReducers(home)

export default reducer
